<template>
	<div>
		<!--banner single blog-->
		<section class="blog_banner singlepost marginTop_fixed">
			<div class="container">
				<div class="row">
					<div class="col-md-6 offset-md-3">
						<h1>{{ title }}</h1>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<section class="contact_blog">
			<div class="container">
				<div class="row">
					<div class="col-md-3">
						<div class="blog_singleTitle sticky-top">
							<ul>
								<li v-for="(post, index) in blog" :key="index">
									<router-link
										:to="{ path: '/' + $i18n.locale + '/blog/' + post.link }"
									>
										{{ post.title }}
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-7">
						<div class="row align-items-center scauthor">
							<div class="col-md-6 col-sm-7 col-7">
								<div class="authorsc">
									<div class="author_img">
										<img
											:src="require(`@/assets/images/${author_image}`)"
											:alt="author"
										/>
									</div>
									<div class="author_info">
										<h5>{{ author }}</h5>
										<h6>{{ date }}</h6>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-sm-5 col-5">
								<div class="social_share">
									<ul>
										<li>
											<a href="#"
												><svg
													width="30"
													height="30"
													viewBox="0 0 30 30"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M23.75 0H6.25C2.79875 0 0 2.79875 0 6.25V23.75C0 27.2013 2.79875 30 6.25 30H23.75C27.2025 30 30 27.2013 30 23.75V6.25C30 2.79875 27.2025 0 23.75 0ZM10 23.75H6.25V10H10V23.75ZM8.125 8.415C6.9175 8.415 5.9375 7.4275 5.9375 6.21C5.9375 4.9925 6.9175 4.005 8.125 4.005C9.3325 4.005 10.3125 4.9925 10.3125 6.21C10.3125 7.4275 9.33375 8.415 8.125 8.415ZM25 23.75H21.25V16.745C21.25 12.535 16.25 12.8538 16.25 16.745V23.75H12.5V10H16.25V12.2063C17.995 8.97375 25 8.735 25 15.3013V23.75Z"
														fill="#0F393B"
													/></svg
											></a>
										</li>
										<li>
											<a href="#"
												><svg
													width="30"
													height="30"
													viewBox="0 0 30 30"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M23.75 0H6.25C2.79875 0 0 2.79875 0 6.25V23.75C0 27.2013 2.79875 30 6.25 30H23.75C27.2025 30 30 27.2013 30 23.75V6.25C30 2.79875 27.2025 0 23.75 0ZM20 8.75H17.595C16.8263 8.75 16.25 9.065 16.25 9.86125V11.25H20L19.7025 15H16.25V25H12.5V15H10V11.25H12.5V8.84625C12.5 6.31875 13.83 5 16.8263 5H20V8.75Z"
														fill="#0F393B"
													/></svg
											></a>
										</li>
										<li>
											<a href="#"
												><svg
													width="30"
													height="30"
													viewBox="0 0 30 30"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M23.75 0H6.25C2.79875 0 0 2.79875 0 6.25V23.75C0 27.2013 2.79875 30 6.25 30H23.75C27.2025 30 30 27.2013 30 23.75V6.25C30 2.79875 27.2025 0 23.75 0ZM23.5763 11.5463C23.8375 17.3175 19.5338 23.7525 11.9137 23.7525C9.59625 23.7525 7.44 23.0738 5.62375 21.9088C7.80125 22.165 9.97375 21.5612 11.6988 20.21C9.9025 20.1762 8.3875 18.99 7.86625 17.36C8.51 17.4825 9.1425 17.4462 9.71875 17.29C7.745 16.8937 6.38375 15.1162 6.4275 13.215C6.98 13.5225 7.61375 13.7075 8.285 13.7288C6.45875 12.5075 5.94125 10.095 7.015 8.25C9.03875 10.7325 12.0625 12.3663 15.4725 12.5375C14.8738 9.97125 16.8225 7.5 19.4712 7.5C20.65 7.5 21.7175 7.9975 22.465 8.79625C23.4 8.6125 24.2788 8.27125 25.0725 7.80125C24.765 8.76 24.115 9.56375 23.2687 10.0712C24.0987 9.97125 24.89 9.75125 25.625 9.425C25.0763 10.245 24.38 10.9675 23.5763 11.5463Z"
														fill="#0F393B"
													/></svg
											></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<!--row-end-->
						<div class="mainblog_content">
							<div class="single_img">
								<img
									:src="require(`@/assets/images/${featured_image}`)"
									:alt="title"
								/>
							</div>

							<div v-html="content"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import eng from "../locales/blog_en.json";
	import ita from "../locales/blog_it.json";
	import MainPage from "@/layouts/MainPage.vue";
	export default {
		props: {
			id: String,
		},
		data() {
			return {
				eng: eng,
				ita: ita,
				title: "",
				content: "",
				featured_image: "",
				author: "",
				author_image: "",
				date: "",
			};
		},
		computed: {
			blog() {
				return this.$i18n.locale === "en" ? this.eng : this.ita;
			},
		},
		methods: {
			update() {
				let postItem = "";
				if (this.$i18n.locale == "en") {
					postItem = (this.$i18n.locale === "en" ? this.eng : this.ita).find(
						(s) => s.link === this.$route.path.slice(6, this.$route.path.length)
					);
				} else {
					postItem = (this.$i18n.locale === "it" ? this.eng : this.ita).find(
						(s) => s.link === this.$route.path.slice(9, this.$route.path.length)
					);
				}
				if (postItem === undefined) this.$router.push({ name: "404 Error" });
				this.title = postItem.title;
				this.content = postItem.content;
				this.featured_image = postItem.featured_image;
				this.author = postItem.author;
				this.author_image = postItem.author_image;
				this.date = postItem.date;
			},
		},
		watch: {
			$route() {
				this.update();
			},
		},
		mounted() {
			this.update();
		},

		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
			this.assignSinglePost();
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},

		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: "SEO Suite",
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: "SEO Suite" },
					{ property: "og:title", content: "SEO Suite" },
					{ property: "og:site_name", content: "SEO Suite" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
